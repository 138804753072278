export enum ECommerceIcon {
    All = "tdsi-multi-chanel-fill",
    TiktokShop = "tdsi-tiktok-fill",
    Shopee = "tdsi-shopee-fill",
    Lazada = "tdsi-lazada-fill",
    Tiki = "tdsi-tiki-fill"
}

export enum ECommerceName {
    All = "Tất cả",
    TiktokShop = "Tiktok Shop",
    Shopee = "Shopee",
    Lazada = "Lazada",
    Tiki = "Tiki"
}

export enum ChannelType {
    All = 100,
    TiktokShop = 0,
    Lazada = 1,
    Tiki = 2,
    Shopee = 3
}